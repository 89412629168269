<template>
  <Wrapper :notice="$t('auth.OPTLogin')">
    <Account @callback="accountCallack" />
    <van-button @click="onVerify" v-debounce :disabled="disabled" class="register-index-wrapper-submit" type="info">{{ $t("auth.sendVerificationCode") }}</van-button>
    <span
      @click="goLogin"
      class="font14 margin-top24 register-index-wrapper-notice"
      >{{ $t("auth.passwordLogin") }}</span
    >
  </Wrapper>
</template>
<script>
import Wrapper from '@/components/auth/wrapper/index.vue'
import Account from '@/components/auth/account'
import { mapActions } from 'vuex'
import { sendEmailSms } from '@/api/auth.js'

export default {
  name: 'SignUp',
  components: { Wrapper, Account },
  data () {
    return {
      accountStatus: 'email',
      auth: {
        accountStatus: 'email',
        email: '',
        mobile_code: '',
        phone: ''
      }
    }
  },
  computed: {
    disabled  () {
      const getStatusObj = {
        phone: () => {
          return !(this.auth.phone)
        },
        email: () => {
          return !(this.auth.email)
        }
      }
      const status = getStatusObj[this.accountStatus]()
      return status
    }
  },
  methods: {
    accountCallack (valueObj) {
      this.auth = { ...valueObj }
      this.accountStatus = valueObj.accountStatus
    },
    goLogin () {
      this.$router.push('/register/login')
    },
    onVerify () {
      const { accountStatus, email, mobile_code: mCode, phone } = this.auth
      const params = {
        email
      }
      this.updatePhone('')
      this.updateMobileCode('')
      if (accountStatus === 'phone') {
        this.updateEmail('')
        delete params.email
        params.mobile = phone
        params.mobile_code = mCode
        this.updatePhone(phone)
        this.updateMobileCode(mCode)
      }
      sendEmailSms(params).then((res) => {
        if (res.code === 0) {
          // account_status: false 用户未注册, true 用户已注册
          const type = res.data.account_status ? 'otp' : 'signUp'
          const resData = res.data
          this.$store.dispatch('updateVerificationAccountStatus', resData.account_status)
          this.$store.dispatch('updateVerificationCountdown', 60)
          // resData.expires_time
          this.$store.dispatch('updateVerificationExpiresTime', new Date())
          this.$router.push(`/register/verification/${type}`)
          this.$toast.clear()
        }
      })
    },
    ...mapActions(['updateEmail', 'updatePhone', 'updateMobileCode'])
  }
}
</script>
<style lang="less">
.register-index-wrapper {
  &-submit {
    margin-top: 48px;
    padding: 24px 0;
    width: 100%;
    height: 96px;
    border-radius: 16px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px; /* 150% */
    background: linear-gradient(
      135deg,
      var(--line-gradient-start-color) 0%,
      var(--line-gradient-end-color) 100%
    );
  }
  &-notice {
    display: inline-block;
    color: var(--primary-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
  }
}
</style>
